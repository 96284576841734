import request from '@/utils/request'

export function getList(params) {
  return request({
    url: '/mzysystem_categorys/load',
    method: 'get',
    params
  })
}
export function DataDictionaryLoad(params) {
  return request({
    url: '/mzysystem_categorys/DataDictionaryLoad',
    method: 'get',
    params
  })
}

export function DataDictionaryByParent_id(params) {
  return request({
    url: '/mzysystem_categorys/DataDictionaryByParent_id',
    method: 'get',
    params
  })
}

export function DataDictionaryByParent_id2(params) {
  return request({
    url: '/mzysystem_categorys/DataDictionaryByParent_id2',
    method: 'get',
    params
  })
}

export function getNmae(token) {
  return request({
    url: '/check/getusername',
    method: 'get',
    params: { token }
  })
}


export function GetListByParent_id(params) {
  return request({
    url: '/mzysystem_categorys/GetListByParent_id',
    method: 'get',
    params
  })
}
export function add(data) {
  return request({
    url: '/mzysystem_categorys/add',
    method: 'post',
    data
  })
}

export function add1(data) {
  return request({
    url: '/mzysystem_categorys/add1',
    method: 'post',
    data
  })
}

export function dataadd(data) {
  return request({
    url: '/mzysystem_categorys/dataadd',
    method: 'post',
    data
  })
}

export function update(data) {
  return request({
    url: '/mzysystem_categorys/update',
    method: 'post',
    data
  })
}

export function del(data) {
  return request({
    url: '/mzysystem_categorys/delete',
    method: 'post',
    data
  })
}

export function Upload(data) {
  return request({
    url: '/mzysystem_categorys/Upload',
    method: 'post',
    data
  })
}

export function GettemplatefieldsByfile(data) {
  return request({
    url: '/mzysystem_categorys/GettemplatefieldsByfile',
    method: 'post',
    data
  })
}




