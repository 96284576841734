import request from '@/utils/request'

export function getList(params) {
  return request({
    url: '/mzysystem_category2s/load',
    method: 'get',
    params
  })
}

export function getinfo(params, additionalParam) {
  return request({
    url: '/mzysystem_category2s/getinfo',
    method: 'get',
    params: {
      param: params,           // 这里使用具体的键名来传递原来的 string 参数
      additionalParam: additionalParam  // 添加新的参数
    }
  });
}


export function LoadList(params) {
  return request({
    url: '/mzysystem_category2s/LoadList',
    method: 'post',
    params
  })
}

export function add(data) {
  return request({
    url: '/mzysystem_category2s/add',
    method: 'post',
    data
  })
}

export function update(data) {
  return request({
    url: '/mzysystem_category2s/update',
    method: 'post',
    data
  })
}

export function del(data) {
  return request({
    url: '/mzysystem_category2s/delete',
    method: 'post',
    data
  })
}


export function getNmae(params) {
  return request({
    url: '/check/lognmae',
    method: 'get',
    params
  })
}


export function getdata(params) {
  return request({
    url: '/mzysystem_category2s/getdata',
    method: 'get',
    params
  })
}